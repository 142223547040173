<template>
	<div class="partial partial--help help">
		<div class="partial__heading">
			<ion-icon name="help-buoy" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Help &amp; Support</h1>
		</div>

		<form-support class="support__form" />
	</div>

</template>

<script>
import FormSupport from '@/components/FormSupport'

export default {
	name: 'partialHelp',
	components: {
		FormSupport,
	},
	metaInfo: () => ({
		title: 'Help & Support',
	}),
}
</script>

<style scoped lang="scss">
.support {
	&__form {
		max-width: 840px;
		width: 100%;
	}
}
</style>
