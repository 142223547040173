<template>
	<form class="form form--support form-support" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">

			<h1 class="form-section__title">How can we help? </h1>
			<p class="form-section__description"></p>

			<div class="form-row">
				<select-field class="form__field" label="Please Select a Service" v-model="fields.appId" :options="services" />
				<text-area class="form__field" label="Comments" id="support_first_name" name="firstName" type="text" autocomplete="given_name" :required="true" v-model="fields.message" :maxlength="350" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions">
				<a-button class="button--primary form__action" type="submit" :disabled="isLoading">Send Request</a-button>
			</div>
		</div>

	</form>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'

export default {
	name: 'formLogin',
	components: {
		AButton,
		LoadingSpinner,
		SelectField,
		TextArea,
		TextField,
	},
	data: () => ({
		error: null,
		fields: {
			service: '',
			comments: '',
		},
		services: [
			{
				"label": "Arc Pricing",
				"value": "arc"
			},
			{
				"label": 'Bond Insight',
				"value": "bond-insight"
			},
			{
				"label": 'Resource Center',
				"value": 'resource-center',
			},
        ],
		isLoading: false
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
	},
	methods: {
		submitForm(fields) {
			this.error = null

			const { appId, message } = fields

			this.isLoading = true
			this.$store.dispatch('application/requestSupport', { appId, message })
				.then((res) => {
					if (res && res.success) {
						Object.keys(this.fields).forEach(k => this.fields[k] = '')
						this.$confirm(`Support Request Sent. We will review your information and get back to you.`, undefined, {
							title: `Request Sent`,
							acceptLabel: `Close`,
							showCancel: false,
						})
					}
				})
				.catch((error) => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.form {
	&__fields {
		display: flex;
		flex-direction: column;
	}

	&__field {
		@include modules.gutter('margin-bottom');
	}

	&__actions {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	&__action {
		margin-bottom: 1em;
	}

	&-row {
		&--col-2 {
			@include modules.media-query-max-width('desktop-xs') {
				grid-template-columns: 1fr;
			}
		}
	}

	&-list {
		@include modules.gutter('margin-bottom');
	}

	&-list-item {
		@include modules.gutter('margin-bottom');

		&__description {
			color: modules.color_('text', 'base');
			display: inline-block;
			margin-left: calc(18px + 1em);
		}

		&__field {
			font-weight: 500;
			margin-bottom: 0.5em;
		}
	}

	&-section {
		&__title {
			@include modules.media-query-min-width('desktop-sm') {
				max-width: 80%;
			}
		}
	}

}
</style>
